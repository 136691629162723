import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import classnames from "classnames";

import Layout from "./layout";

const Page = (props) => {
  const { data } = props;

  const { html, frontmatter, parent } = data.markdownRemark;

  const [isModalVisible, setIsModalVisible] = useState<number | null>(null);

  const refDialogs = useRef<Array<HTMLDialogElement | null>>([]);

  useEffect(() => {
    if (!frontmatter.fitxers) return;

    refDialogs.current = refDialogs.current.slice(
      0,
      frontmatter.fitxers.length
    );
  }, [frontmatter.fitxers]);

  useEffect(() => {
    if (isModalVisible !== null) {
      refDialogs.current[isModalVisible]?.showModal();
    } else {
      refDialogs.current.forEach((dialog) => dialog?.close());
    }
  }, [isModalVisible]);

  const dir = parent.relativePath.split("/")[0];

  return (
    <Layout pageTitle={frontmatter.titol}>
      <section className="Material">
        <div className="Material__side">
          <div className="Material__sideContents">
            <img
              src={frontmatter.imatge.publicURL}
              className="Material__image"
            />

            {frontmatter.enllacos && (
              <>
                <div className="Material__download">
                  Continguts de la pàgina:
                </div>
                <ul className="Material__links">
                  {frontmatter.enllacos.map(({ id, titol }, idx) => {
                    return (
                      <li key={id}>
                        <a className="Btn" href={`#${id}`}>
                          {titol}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            {frontmatter.fitxers && (
              <>
                <div className="Material__download">
                  Descarrega el material:
                </div>
                <ul className="Material__links">
                  {frontmatter.fitxers.map(
                    ({ variants, fitxer, titol, etiqueta }, idx) => {
                      const variantLabels = {
                        lligada: "Lligada",
                        script: "Script",
                        majuscules: "Majúscules",
                      };

                      if (variants) {
                        return (
                          <>
                            <li key={titol}>
                              <div
                                className="Btn Btn--withIcon"
                                onClick={() => setIsModalVisible(idx)}
                              >
                                {titol}
                                {etiqueta && (
                                  <span className="Btn__label">{etiqueta}</span>
                                )}
                              </div>
                            </li>

                            <dialog
                              className="Dialog"
                              ref={(el) => (refDialogs.current[idx] = el)}
                            >
                              <div className="Dialog__header">
                                <h2>Escull la variant</h2>
                                <div
                                  className="Dialog__close"
                                  onClick={() => setIsModalVisible(null)}
                                >
                                  &times;
                                </div>
                              </div>

                              {variants.map(({ variant, fitxer, idioma }) => {
                                return (
                                  <li key={fitxer}>
                                    <a
                                      className={classnames(
                                        "Btn",
                                        `Btn--${variant}`
                                      )}
                                      href={`/fitxers/${dir}/${fitxer}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {variantLabels[variant]}
                                      {idioma && (
                                        <span className="Btn__lang">
                                          {idioma}
                                        </span>
                                      )}
                                    </a>
                                  </li>
                                );
                              })}
                            </dialog>
                          </>
                        );
                      }

                      return (
                        <li key={fitxer}>
                          <a
                            className="Btn"
                            href={`/fitxers/${dir}/${fitxer}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {titol}
                            {etiqueta && (
                              <span className="Btn__label">{etiqueta}</span>
                            )}
                          </a>
                        </li>
                      );
                    }
                  )}
                </ul>
              </>
            )}
          </div>
        </div>

        <div className="Material__body">
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        titol
        imatge {
          publicURL
        }
        enllacos {
          titol
          id
        }
        fitxers {
          variants {
            variant
            idioma
            fitxer
          }
          fitxer
          titol
          etiqueta
        }
      }
      html
    }
  }
`;

export default Page;
